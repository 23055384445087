import React, { Component } from "react";

class Date extends Component {
  render() {
    let displayDate = this.props.date.replace(/-/g, ".");
    let mailToLink = `mailto:lotfiecontact@gmail.com?subject=Booking concert of ${displayDate}, ${this.props.city}`;
    return (
      <li className="event">
        <div className="wrap first">
          <span className="date">{displayDate}</span>
          <span className="venue">{this.props.venue}</span>
        </div>
        <div className="wrap second">
          <div className="location">{this.props.city}</div>
          <div className="country">{this.props.country}</div>
        </div>
        <div className="wrap third">
          <a
            href={mailToLink}
            target="_BLANK"
            rel="noopener noreferrer"
            className="buy"
          >
            Tickets
          </a>
        </div>
      </li>
    );
  }
}

class Tour extends Component {
  constructor(props) {
    super(props);

    const initialDates = [
      {
        id: 1,
        date: "2023-08-16",
        venue: "NGBG Garden",
        city: "Malmö",
        country: "Sweden",
        link: "https://example.com/link1",
      },
      {
        id: 1,
        date: "2023-08-19",
        venue: "Private concert",
        city: "Lund",
        country: "Sweden",
        link: "https://example.com/link1",
      },
      // You can add more dates here in the same format as the one above
    ];

    this.state = {
      dates: initialDates,
    };
  }

  render() {
    if (!this.state.dates) {
      return null;
    }
    return (
      <ul id="tours">
        {this.state.dates.map((date, index) => (
          <Date
            key={index}
            id={date.id}
            date={date.date}
            venue={date.venue}
            city={date.city}
            country={date.country}
            link={date.link}
          />
        ))}
      </ul>
    );
  }
}

export default Tour;
