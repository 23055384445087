import React, { Component } from "react";
import Slider from "react-slick";
// import YouTube from 'react-youtube';
import "./carousel.scss";

import play from "../../assets/img/SVG/play.svg";

const videos = [
  {
    index: "1",
    thumb: "https://i.ytimg.com/vi/-so3bYhBfAw/maxresdefault.jpg",
    url: "https://www.youtube.com/embed/-so3bYhBfAw",
    title: "Lotfi E. // LIVE SESSION",
  },
  {
    index: "2",
    thumb: "https://i.ytimg.com/vi/TWM4d1W5deM/maxresdefault.jpg",
    url: "https://www.youtube.com/embed/TWM4d1W5deM",
    title: "LOTFI E - CML SESSIONS ( Official Music Video )",
  },
  {
    index: "3",
    thumb: "https://i3.ytimg.com/vi/Z7tA_VHzLNE/maxresdefault.jpg",
    url: "https://www.youtube.com/embed/T99Jki5OCcs",
    title: "Lotfi E. // Best Of @ Yes Senses Festival",
  },
];

class VideoSlider extends Component {
  constructor() {
    super();
    this.state = {
      playing: false,
      url: "",
    };
  }
  playVideo = (url) => {
    this.setState({
      playing: true,
      url: url,
    });
  };
  render() {
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const slides = videos.map((item) => {
      return (
        <div key={item.index}>
          <div className="slide">
            <h2>{item.title}</h2>
            <div
              className="slideContent"
              onClick={this.playVideo.bind(this, item.url)}
            >
              {this.state.playing && this.state.url === item.url ? (
                <iframe
                  src={this.state.url}
                  title={item.title}
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              ) : (
                <div className="placeholder">
                  <img className="playButton" src={play} alt="play" />
                  <img src={item.thumb} alt={item.title} />
                </div>
              )}
              ;
            </div>
          </div>
        </div>
      );
    });
    return <Slider {...settings}>{slides}</Slider>;
  }
}

export default VideoSlider;
