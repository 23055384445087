import React from "react";

import photo3 from "../assets/img/photos/image00003.jpeg";
import photo5 from "../assets/img/photos/image00005.jpeg";
import photo7 from "../assets/img/photos/image00004.jpeg";
import photo8 from "../assets/img/photos/1a.jpg";
import photo9 from "../assets/img/photos/2a.jpg";
import photo10 from "../assets/img/photos/3a.jpg";
import photo11 from "../assets/img/photos/4a.JPG";
import photo12 from "../assets/img/photos/5a.jpg";

const photos = [
  {
    key: "1",
    img: [photo8],
  },
  {
    key: "2",
    img: [photo9],
  },
  {
    key: "3",
    img: [photo10],
  },
  {
    key: "5",
    img: [photo11],
  },
  {
    key: "8",
    img: [photo12],
  },
];

class Photos extends React.Component {
  render() {
    const Photos = photos.map((item) => {
      return <img src={item.img} loading="eager|lazy" alt="" key={item.key} />;
    });
    return <div>{Photos}</div>;
  }
}

export default Photos;
