import React, { Component } from "react";

import Mailchimp from "react-mailchimp-form";

import heard from "../assets/img/SVG/heard.svg";

import facebook from "../assets/img/SVG/facebook.svg";
import instagram from "../assets/img/SVG/instagram.svg";
import twitter from "../assets/img/SVG/twitter.svg";
import youtube from "../assets/img/SVG/youtube.svg";
import spotify from "../assets/img/SVG/icons8-spotify.svg";

import youtubeMobile from "../assets/img/SVG/youtube_mobile.svg";

class Footer extends Component {
  getYear() {
    return new Date().getFullYear();
  }
  render() {
    return (
      <footer>
        <div className="emailForm">
          <h6>Subscribe to Mailing List</h6>
          <Mailchimp
            action="https://gmail.us8.list-manage.com/subscribe/post?u=bdc7b5107f8991ab9416fda77&amp;id=263fb9c84b&amp;f_id=004004e0f0"
            fields={[
              {
                name: "EMAIL",
                placeholder: "Email...",
                type: "email",
                required: true,
              },
            ]}
            messages={{
              sending: "Sending...",
              success: "Thank you. You're on the list!",
              error: "Oops, that didn't work.",
              empty: "Please submit a vaild email address.",
              duplicate: "This email address has already joined.",
              button: "Submit",
            }}
          />
          <div className="showMobile">© {this.getYear()} Lotfi E.</div>
        </div>
        <article id="social" className="hideMobile">
          <a
            id="facebook"
            href="https://www.facebook.com/lotfiemusic"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="" />
          </a>
          <a
            id="instagram"
            href="https://www.instagram.com/lotfloyd/"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
          <a
            id="spotify"
            href="https://open.spotify.com/artist/0RjOozZPjjskFS1FGgYbz9?si=DVL5KkZASpqCB6ygUalKRg"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <img src={spotify} alt="" />
          </a>
          <a
            className="hideMobile"
            id="youtube"
            href="https://www.youtube.com/lotfie"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <img src={youtube} alt="" />
          </a>
          <a
            className="showMobile"
            id="youtube"
            href="https://www.youtube.com/lotfie"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <img src={youtubeMobile} alt="" />
          </a>
        </article>
        <p id="legal" className="hideMobile">
          <span>© {this.getYear()} Lotfi E.</span>{" "}
        </p>
      </footer>
    );
  }
}

export default Footer;
